import { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import config from '../../Services/Config/appConfig';
import Cookies from 'js-cookie';
import { ToastContext } from '../../Helpers/context';
import { BaseComponent } from '../Shared/BaseComponent';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';

const TwoFactor = ({ twoFA }) => {
	const [postLoginPath, setPostLoginPath] = useState('');
	const { t } = useTranslation();

	// UseContext to show notification
	const notification = useContext(ToastContext);

	useEffect(() => {
		if (Cookies.get('error') && JSON.parse(Cookies.get('error'))) {
			const error = JSON.parse(Cookies.get('error'));
			Cookies.remove('error');
			notification(error.message);
		}
		const search = window.location.search;
		const queryParams = new URLSearchParams(search);
		const params = {};
		for (let param of queryParams.entries()) {
			const [key, value] = param;
			params[key] = value;
		}
		const q = Object.entries(params)
			.map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
			.join('&');
		const apiLoginPath = q
			? `${config.api.baseUrl}${config.api.endpoint.login}?${q}`
			: `${config.api.baseUrl}${config.api.endpoint.login}`;
		setPostLoginPath(apiLoginPath);
	}, [notification, twoFA]);

	return (
		<>
			<BaseComponent title={t("Two-factor authentication")}>
				<Card>
					<div className="w-full">
						<div>
							<form action={postLoginPath} method="post">
								<div>
									<span className="p-input-icon-left field w-full">
										<i className="pi pi-key" />
										<Password
											name="code"
											placeholder={t("code")}
											className="w-full iwi"
											minLength={6}
											maxLength={6}
											feedback={false}
											required
										/>
									</span>
									<div className="mt-3">
										<Button
											label={t("submit")}
											className="p-button-primary w-full btn-font-size pt-3 pb-3"
											type="submit"
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="text-center m-30">
						<span className='color-primary cursor-pointer'>{t('back')}</span>
					</div>
				</Card>
			</BaseComponent>
		</>
	);
}

export default TwoFactor;