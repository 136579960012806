
import { ProgressSpinner } from 'primereact/progressspinner';

const Loader = () => {
    return (
        <div style={loaderStyle}>
            <ProgressSpinner />
        </div>
    );
};

export default Loader;

export const loaderStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
}