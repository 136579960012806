import { lazy } from "react";
import { Paths } from "./Paths";

export const ADMIN = "ADMIN";

export const PublicRoutes = [
    {
        path: Paths.forgotPassword,
        ComponentIn: lazy(() => import("../Components/User/ForgotPassword"))
    },
    {
        path: Paths.changePassword,
        ComponentIn: lazy(() => import("../Components/User/ChangePassword"))
    },
    {
        path: Paths.editProfile,
        ComponentIn: lazy(() => import("../Components/Profile/EditProfile"))
    },
    {
        path: Paths.verify,
        ComponentIn: lazy(() => import("../Components/User/Verify"))
    },
    {
        path: Paths.nf,
        ComponentIn: lazy(() => import("../Components/404/index"))
    }
];

// export const PrivateRoutes = {
//     ADMIN: [
//     ]
// };

export const PrivateRoutes = [
    {
        path: Paths.setting,
        ComponentIn: lazy(() => import("../Components/Settings/Settings"))
    },
    {
        path: Paths.dashboard,
        ComponentIn: lazy(() => import("../Components/Dashboard/Dashboard"))
    }
]

export const AuthRoutes = [
    {
        path: Paths.register,
        ComponentIn: lazy(() => import("../Components/User/Register"))
    },
    {
        path: Paths.login,
        ComponentIn: lazy(() => import("../Components/User/Login"))
    },
    {
        path: Paths.base,
        ComponentIn: lazy(() => import("../Components/User/Login"))
    },
    {
        path:Paths.errorPage,
        ComponentIn: lazy(() => import("../Components/ClientErrors/Unauthorised"))
    },
    {
        path:Paths.emailSms2fa,
        ComponentIn: lazy(() => import("../Components/User/EmailOrSmsTwoFactor"))
    },
    {
        path: Paths.passwordLessLogin,
        ComponentIn: lazy(() => import("../Components/User/PasswordLessLogin"))
    },
    {
        path: Paths.authenticateOtp,
        ComponentIn: lazy(() => import("../Components/User/EnterOtp"))
    },
    {
        path: Paths.setPin,
        ComponentIn: lazy(() => import("../Components/User/SetPin"))
    },
    {
        path: Paths.forgetPin,
        ComponentIn: lazy(() => import("../Components/User/ForgotPin"))
    }
]