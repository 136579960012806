import React, { Suspense, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import TwoFactor from './Components/User/TwoFactor';
import Cookies from 'js-cookie';
import { PublicRoutes, PrivateRoutes, AuthRoutes } from "./Router/Routes";
import { ToastContext } from './Helpers/context';
import { Toast } from 'primereact/toast';
import Loader from './Components/Shared/Loader';

function App() {

  const twoFA = Cookies.get('u.tfa');
  const authType = Cookies.get('2FaType');

  // notification component
  const toast = useRef(null);

  // Get User Current role 
  // let CurrentUserRole = "ADMIN";

  // callback for toast notification
  const notification = (summary, severity = 'error') =>
    toast.current.show({
      life: 5000,
      severity,
      summary,
    });

  return (
    <>
      <ToastContext.Provider value={notification}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Auth Routes */}
              <Route element={<Routing />} >
                {AuthRoutes.map(({ path, ComponentIn }, key) => {
                  if (path === "register" || path ==="email-sms-authentication") {
                    return <Route key={key} path={path} element={<ComponentIn />} />;
                  }
                  return <Route key={key} path={path} element={twoFA && authType === "authenticator" ? <TwoFactor twoFA={twoFA} /> : <ComponentIn />} />;
                })}
              </Route>
              {/* public routes  */}
              {PublicRoutes.map(({ path, ComponentIn }, key) => {
                return <Route key={key} path={path} element={<ComponentIn />} />;
              })}
              {/* private routes based on Roles */}
              <Route element={<PrivateRoute />} >
                {PrivateRoutes.map(({ path, ComponentIn }, key) => {
                  return <Route key={key} path={path} element={<ComponentIn />} />;
                })}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ToastContext.Provider>
      {/* Reference for toast component */}
      <Toast ref={toast} />
    </>
  );
}

// Verify auth token and re-direct to dashboard 
export function Routing() {
  const isAuthenticated = Cookies.get('authToken');

  if (isAuthenticated) {
    //return <Navigate to="/dashboard" />;
    window.location.href="/dashboard"
  }

  return (
    <>
      <Outlet />
    </>
  )
}

// verify private routes
export function PrivateRoute() {
  const isAuthenticated = Cookies.get('authToken');

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <Outlet />
    </>
  )
}

export default App;
