export const Paths = {
    base: "/",
    nf: "*",
    login: "login",
    register: "register",
    forgotPassword: "reset-password",
    changePassword: "change-password",
    editProfile: "edit-profile",
    verify: "verify",
    setting: "setting",
    dashboard: "dashboard",
    errorPage: "error",
    emailSms2fa: "email-sms-authentication",
    passwordLessLogin: "signin",
    authenticateOtp: "authenticate-otp",
    setPin: "set-pin",
    forgetPin: "forget-pin"
  };
  